/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { legacyTimeZoneMap as e } from "./legacyTimeZoneMap.js";
import { DateTime as t, FixedOffsetZone as n } from "luxon";
const o = "system",
  r = "unknown",
  i = "UTC",
  s = Intl.DateTimeFormat().resolvedOptions().timeZone,
  a = "shortOffset";
function u(e) {
  return "timeZoneIANA" in e;
}
function m(e) {
  return e.startsWith("UTC");
}
function c(e, t = !1) {
  return {
    json: {
      read: {
        source: e,
        reader: (t, n) => n[e] ? f(n[e]) : null
      },
      write: !!t && {
        allowNull: !0,
        writer(t, n) {
          n[e] = t ? l(t) : null;
        }
      }
    }
  };
}
function f(e, t = o) {
  return e ? u(e) ? e.timeZoneIANA : Z(e, t) : t;
}
function l(e) {
  return {
    timeZoneIANA: e
  };
}
function Z(t, n = o) {
  if (!t || !e.has(t.timeZone)) return n;
  const r = e.get(t.timeZone);
  return m(t.timeZone) || t.respectsDaylightSaving ? r : w(r);
}
function w(e) {
  const o = t.local().setZone(e),
    r = Math.min(o.set({
      month: 1,
      day: 1
    }).offset, o.set({
      month: 5
    }).offset);
  if (0 === r) return "Etc/UTC";
  return `Etc/GMT${n.instance(-r).formatOffset(0, "narrow")}`;
}
function p(e, t, n, o, s) {
  if (t && "date" === s) return {
    timeZone: i,
    timeZoneName: void 0
  };
  const u = o.timeStyle || o.hour,
    m = n === r;
  return {
    timeZone: m ? "timestamp-offset" === s ? void 0 : e ?? i : n,
    timeZoneName: m && u ? a : void 0
  };
}
function g(e) {
  const t = e === o ? s : e,
    n = /^(?<area>[\w-]+)(?:\/(?<region>[\w-]+))?\/(?<location>[\w+-]+)$/;
  if (n.test(t)) {
    const e = n.exec(t)?.groups,
      {
        area: o,
        region: r,
        location: i
      } = e;
    return {
      area: o,
      region: r,
      location: i,
      timeZone: t
    };
  }
  return {
    timeZone: t
  };
}
function h(e) {
  const t = new Set(["etc/utc", "etc/gmt", "gmt"]),
    n = new Set(Intl.supportedValuesOf("timeZone").map(e => e.toLowerCase()));
  return e === o || e === r || t.has(e.toLowerCase()) || n.has(e.toLowerCase());
}
export { Z as convertLegacyTimeZone, f as fromTimeReference, g as getTimeZoneComponents, p as getTimeZoneFormattingOptions, h as isTimeZoneValid, a as shortTimeZoneName, o as system, s as systemTimeZone, c as timeZoneProperty, l as toTimeReference, r as unknown, i as utc };